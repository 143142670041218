<!--=========================================================================================
  File Name: BettingArea.vue
  Description: Bull Betting Area
==========================================================================================-->
<template>
<b-container class="bull-bet-area" fluid>
    <b-row>
        <b-col cols="4">
        </b-col>
        <b-col cols="4">
            <div v-if="isBetClosed || initBetting" :class="['closed-bet-area']" class="notif-div">
                <span v-if="!currentRoom.result" :class="['betting-closed-message']" class='bet-notif-span'>
                    {{ $t('game.bettingMessage.closed') }}
                </span>
            </div>
            <div v-if="isNotificationOn" :class="['notification-area']">
                <div :class="['notification-container']">
                    <span class='bet-notif-span' :class="['notification-message']">
                        {{ $t('game.bettingNotification.' + betResponse) }}
                    </span>
                </div>
            </div>
            <div v-if="showError" :class="['notification-area']">
                <div :class="['notification-container']">
                    <span :class="['notification-message']">
                        {{ errorMessage }}
                    </span>
                </div>
            </div>
        </b-col>
        <b-col cols="4">
        </b-col>
    </b-row>
    <b-row :class="[{'betting-area-disable' : isBetClosed || initBetting}]">
        <b-col cols="1" style="margin-right: 50px;"></b-col>
        <b-col cols="3" class="bet-area-col bet-area-col-left ">
            <b-row class="bet-row">
                <b-col class="bet-col" :class="[{'winbanker': resultShow.b1}]">
                    <div @click="placeBet(getChipSelectedChip(),'b1')">
                        <span class="bet-area-label-top-bl banker-text-1">BANKER 1</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer1BankerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="bet-row">
                <b-col class="bet-col bet-col-middle" :class="[{'winbull': resultShow.b1b}]">
                    <div @click="placeBet(getChipSelectedChip(),'b1b')">
                        <span class="bet-area-label-top-bl banker-text-1 bet-area-mid">B1 B. B</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer1BankerBBBet | currency('')}}</span>
                    </div>
                </b-col>
                <b-col class="bet-col" style="border-right:0px;" :class="[{'winbull': resultShow.p1b}]">
                    <div @click="placeBet(getChipSelectedChip(),'p1b')">
                        <span class="bet-area-label-top-bl player-text bet-area-mid">P1 B. B</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer1PlayerBBBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="bet-col" :class="[{'winplayer': resultShow.p1}]">
                    <div @click="placeBet(getChipSelectedChip(),'p1')">
                        <span class="bet-area-label-top-bl player-text">PLAYER 1</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer1PlayerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="3" class="bet-area-col border-bot-center" style="transform:matrix(1.4, 0, 0, 0.8, 0.4, 0);border:0px;border-top: 3px solid #cecece !important;">
            <b-row class="bet-row">
                <b-col class="bet-col" :class="[{'winbanker': resultShow.b2}]">
                    <div @click="placeBet(getChipSelectedChip(),'b2')">
                        <span class="bet-area-label-top-bl banker-text-1">BANKER 2</span>
                        <span class="bet-area-label-bot-bl bet-area-banker-mr" style="padding-right:3.5vw !important">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer2BankerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="bet-row">
                <b-col class="bet-col bet-col-middle" :class="[{'winbull': resultShow.b2b}]">
                    <div @click="placeBet(getChipSelectedChip(),'b2b')">
                        <span class="bet-area-label-top-bl banker-text-1 bet-area-mid">B2 B. B</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer2BankerBBBet | currency('')}}</span>
                    </div>
                </b-col>
                <b-col class="bet-col" style="border-right:0px;" :class="[{'winbull': resultShow.p2b}]">
                    <div @click="placeBet(getChipSelectedChip(),'p2b')">
                        <span class="bet-area-label-top-bl player-text bet-area-mid">P2 B. B</span>
                        <span class="bet-area-label-bot-bl" style="padding-right:2.3vw !important">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer2PlayerBBBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="bet-row">
                <b-col class="bet-col" :class="[{'winplayer': resultShow.p2}]">
                    <div @click="placeBet(getChipSelectedChip(),'p2')">
                        <span class="bet-area-label-top-bl player-text">PLAYER 2</span>
                        <span class="bet-area-label-bot-bl" style="padding-right:1.2vw !important">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer2PlayerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="3" class="bet-area-col bet-area-col-right">
            <b-row class="bet-row">
                <b-col class="bet-col" :class="[{'winbanker': resultShow.b3}]">
                    <div @click="placeBet(getChipSelectedChip(),'b3')">
                        <span class="bet-area-label-top-bl banker-text-1">BANKER 3</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer3BankerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="bet-row">
                <b-col class="bet-col bet-col-middle" :class="[{'winbull': resultShow.b3b}]">
                    <div @click="placeBet(getChipSelectedChip(),'b3b')">
                        <span class="bet-area-label-top-bl banker-text-1 bet-area-mid">B3 B. B</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer3BankerBBBet | currency('')}}</span>
                    </div>
                </b-col>
                <b-col class="bet-col" style="border-right:0px;" :class="[{'winbull': resultShow.p3b}]">
                    <div @click="placeBet(getChipSelectedChip(),'p3b')">
                        <span class="bet-area-label-top-bl player-text bet-area-mid">P3 B. B</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer3PlayerBBBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="bet-col" :class="[{'winplayer': resultShow.p3}]">
                    <div @click="placeBet(getChipSelectedChip(),'p3')">
                        <span class="bet-area-label-top-bl player-text">PLAYER 3</span>
                        <span class="bet-area-label-bot-bl">1:095</span>
                        <span class="bet-area-val-bl">{{totalPlayer3PlayerBet | currency('')}}</span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="1"></b-col>
    </b-row>
    <b-row>
        <betting-action :bet="bet" :successfulBet="successfulBet" @initBet="initBet" @resetGame="resetGame" @setErrorMessage="setErrorMessage" :prevBetTime="this.prevBetTime" />
    </b-row>
</b-container>
</template>

<script>
import BettingAction from '@/components/bull/game/BettingActions'

export default {
    components: {
        BettingAction
    },
    data() {
        return {
            bet: [],
            game: {
                chipSelected: 0
            },
            successfulBet: {
                b1: 0,
                p1: 0,
                b1b: 0,
                p1b: 0,
                b2: 0,
                p2: 0,
                b2b: 0,
                p2b: 0,
                b3: 0,
                p3: 0,
                b3b: 0,
                p3b: 0,
            },
            showResult: false,
            resultShow: {
                p1: false,
                b1: false,
                b1b: false,
                p1b: false,
                p2: false,
                b2: false,
                b2b: false,
                p2b: false,
                p3: false,
                b3: false,
                b3b: false,
                p3b: false,
            },
            initBetting: true,
            isBetReset: false,
            isBetCleared: false,
            counterAdded: false,
            currentRoomOrder: '',
            showError: false,
            errorMessage: '',
            noBetCounter: 0,
            prevBetTime: 0
        }
    },
    computed: {
        member() {
            return this.$store.state.gameModule.member
        },
        limit() {
            return this.$store.getters['gameModule/getCurrentLevelLimit']
        },
        currentRoom() {
            return this.$store.state.gameModule.currentRoom;
        },
        isBetClosed() {
            return this.$store.state.gameModule.currentRoom.state != 1;
        },
        chips() {
            return this.$store.state.gameModule.betChips;
        },
        betResponse() {
            return this.$store.state.betModule.notifications.message;
        },
        isNotificationOn() {
            return this.$store.state.betModule.notifications.show;
        },
        maxAllowedNoBet() {
            return this.$store.state.gameModule.settings.maxRoundRoomKick;
        },
        showFireworks() {
            return this.$store.state.gameModule.settings.showFireworks;
        },
        gameStateLabel() {

            let label = null

            switch (this.currentRoom.state) {
                case 0: {
                    label = this.$t('gameState.readyBetting');
                    break;
                }
                case 1: {
                    label = this.$t('gameState.startBetting');
                    break;
                }
                case 2: {
                    label = this.$t('gameState.stopBetting');
                    break;
                }
                case 3: {
                    //CHANGE TO BULL RESULTS
                    /*if(this.currentRoom.result == 'a' || this.currentRoom.result == 'b' || this.currentRoom.result == 'c' || this.currentRoom.result == 'a'){
                        label = this.$t('gameState.bankerWin');
                        this.playAudio('banker-win');
                    }
                    else if(this.currentRoom.result == 'e' || this.currentRoom.result == 'f' || this.currentRoom.result == 'g' || this.currentRoom.result == 'h') {
                        label = this.$t('gameState.playerWin');
                        this.playAudio('player-win');
                    }
                    else if(this.currentRoom.result == 'i' || this.currentRoom.result == 'j' || this.currentRoom.result == 'k' || this.currentRoom.result == 'l') {
                        label = this.$t('gameState.tieWin');
                        this.playAudio('tie-win');
                    }*/

                    break;
                }
                case 4:
                    label = this.$t('gameState.shuffling')
            }

            if (this.initBetting) {
                label = this.$t('gameState.nextBetting');
            }

            return label
        },
        gameState() {
            return this.currentRoom.state;
        },
        gameResult() {
            return this.$store.state.gameModule.currentRoom.result;
        },
        completedBet() {
            return this.$store.state.betModule.bettings;
        },
        totalPlacedBet() {
            var returnAmount = 0;
            if (this.bet) {
                this.bet.forEach((key, item) => {
                    returnAmount += parseInt(this.bet[item].betamt);
                });
            }
            return returnAmount;
        },
        totalPlayer1BankerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b1'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b1'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b1 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer1BankerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b1b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b1b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b1b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer1PlayerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p1b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p1b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p1b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer1PlayerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p1'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p1'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p1 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer2BankerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b2'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b2'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b2 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer2BankerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b2b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b2b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b2b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer2PlayerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p2b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p2b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p2b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer2PlayerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p2'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p2'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p2 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer3BankerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b3'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b3'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b3 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer3PlayerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p3'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p3'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p3 = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer3BankerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['b3b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['b3b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.b3b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalPlayer3PlayerBBBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function (itm) {
                    return ['p3b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function (itm) {
                    return ['p3b'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.p3b = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
    },
    methods: {
        getChipSelectedChip() {
            let chipamount = this.$store.state.gameModule.selectedchip;
            return chipamount;
        },
        placeBet(amount, target) {

            if (this.isBetClosed || this.initBetting) {
                this.setErrorMessage('BET_CLOSED');
                return false;
            }

            if (this.member.balance <= 0) {
                this.setErrorMessage('INSUFFICIENT_FUNDS');
                return false;
            }

            if (this.member.balance < amount + this.totalPlacedBet) {
                amount = this.member.balance - this.totalPlacedBet;
                if (amount <= 0) {
                    this.setErrorMessage('INSUFFICIENT_FUNDS');
                    return false;
                }
            }

            let betObj = {
                betpos: target,
                betamt: amount
            };

            var betAmount;

            // Check if betposition already exists in the array
            const checkIfBetposExist = this.bet.some(o => o.betpos === target)

            // Update Array
            if (checkIfBetposExist) {
                const idx = this.bet.findIndex(b => b.betpos == target)
                var newAmount = parseInt(this.bet[idx].betamt) + parseInt(amount);

                betAmount = this.limitBet(newAmount, this.bet[idx].betpos);

                betAmount = newAmount
                this.bet[idx].betamt = betAmount;

            } else {
                betAmount = this.limitBet(amount, target);

                betAmount = amount;
                betObj.betamt = betAmount;
                this.bet.push(betObj)
            }

            this.isBetReset = false;
            this.isBetCleared = false;

            //console.log(this.bet);
        },
        limitBet(amount, target) {
            var betMin, betMax,
                totalBet = 0;

            switch (target) {
                case 'b1':
                    betMin = this.limit.banker_min;
                    betMax = this.limit.banker_max;
                    if (this.successfulBet.b1) {
                        totalBet = this.successfulBet.b1;
                    }
                    break;
                case 'p1':
                    betMin = this.limit.player_min;
                    betMax = this.limit.player_max;
                    totalBet = this.successfulBet.p1;
                    if (this.successfulBet.p1) {
                        totalBet = this.successfulBet.p1;
                    }
                    break;
                case 'b1b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    if (this.successfulBet.b1b) {
                        totalBet = this.successfulBet.b1b;
                    }
                    break;
                case 'p1b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    totalBet = this.successfulBet.p1b;
                    if (this.successfulBet.p1b) {
                        totalBet = this.successfulBet.p1b;
                    }
                    break;
                case 'b2':
                    betMin = this.limit.banker_min;
                    betMax = this.limit.banker_max;
                    if (this.successfulBet.b2) {
                        totalBet = this.successfulBet.b2;
                    }
                    break;
                case 'p2':
                    betMin = this.limit.player_min;
                    betMax = this.limit.player_max;
                    totalBet = this.successfulBet.p2;
                    if (this.successfulBet.p2) {
                        totalBet = this.successfulBet.p2;
                    }
                    break;
                case 'b2b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    if (this.successfulBet.b2b) {
                        totalBet = this.successfulBet.b2b;
                    }
                    break;
                case 'p2b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    totalBet = this.successfulBet.p2b;
                    if (this.successfulBet.p2b) {
                        totalBet = this.successfulBet.p2b;
                    }
                    break;
                case 'b3':
                    betMin = this.limit.banker_min;
                    betMax = this.limit.banker_max;
                    if (this.successfulBet.b3) {
                        totalBet = this.successfulBet.b3;
                    }
                    break;
                case 'p3':
                    betMin = this.limit.player_min;
                    betMax = this.limit.player_max;
                    totalBet = this.successfulBet.p3;
                    if (this.successfulBet.p3) {
                        totalBet = this.successfulBet.p3;
                    }
                    break;
                case 'b3b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    if (this.successfulBet.b3b) {
                        totalBet = this.successfulBet.b3b;
                    }
                    break;
                case 'p3b':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    totalBet = this.successfulBet.p3b;
                    if (this.successfulBet.p3b) {
                        totalBet = this.successfulBet.p3b;
                    }
                    break;
            }

            if (totalBet + amount < betMin) {
                amount = parseInt(betMin) - totalBet;
            } else if (totalBet + amount > betMax) {
                amount = parseInt(betMax) - totalBet;
            }
            return parseInt(amount);
        },
        initBet() {
            this.bet = [];
        },
        resetBet() {
            this.bet = [];
            this.successfulBet = {};
            this.isBetReset = true;
            this.prevBetTime = 0;
        },
        resetGame() {
            console.log("RESET GAME");
            this.resetBet();
            this.$store.dispatch('betModule/betClear');
            this.successfulBet.b1 = 0;
            this.successfulBet.p1 = 0;
            this.successfulBet.b1b = 0;
            this.successfulBet.p1b = 0;
            this.successfulBet.b2 = 0;
            this.successfulBet.p2 = 0;
            this.successfulBet.b2b = 0;
            this.successfulBet.p2b = 0;
            this.successfulBet.b3 = 0;
            this.successfulBet.p3 = 0;
            this.successfulBet.b3b = 0;
            this.successfulBet.p3b = 0;
            this.clearResultShow();
        },
        clearResultShow() {
            this.resultShow = {
                p1: false,
                b1: false,
                b1b: false,
                p1b: false,
                p2: false,
                b2: false,
                b2b: false,
                p2b: false,
                p3: false,
                b3: false,
                b3b: false,
                p3b: false,
            };
        },
        checkResultWin(result) {
            switch (result) {
                case 'p1':
                    this.resultShow.p1 = true;
                    break;
                case 'p2':
                    this.resultShow.p2 = true;
                    break;
                case 'p3':
                    this.resultShow.p3 = true;
                    break;
                case 'b1':
                    this.resultShow.b1 = true;
                    break;
                case 'b2':
                    this.resultShow.b2 = true;
                    break;
                case 'b3':
                    this.resultShow.b3 = true;
                    break;
                case 'p1b':
                    this.resultShow.p1b = true;
                    break;
                case 'p2b':
                    this.resultShow.p2b = true;
                    break;
                case 'p3b':
                    this.resultShow.p3b = true;
                    break;
                case 'b1b':
                    this.resultShow.b1b = true;
                    break;
                case 'b2b':
                    this.resultShow.b2b = true;
                    break;
                case 'b3b':
                    this.resultShow.b3b = true;
                    break;
            }
        },
        setErrorMessage(errorCode) {
            this.errorMessage = this.$t('game.errorMessage.' + errorCode);
            this.showError = true;
            setTimeout(() => this.showError = false, 1000);
        },
        playAudio(id) {
            this.$emit('playAudio', id);
        },
        refreshBalance() {
            this.$store.dispatch('betModule/refreshBalance');
        },
        addBetCounter() {
            this.noBetCounter++;
        },
        initBettingSetFalse() {
            this.initBetting = true;
        },
    },
    watch: {
        isBetClosed: {
            handler() {
                if (this.$store.state.gameModule.currentRoom.state != 1 && this.initBetting) {
                    this.initBetting = false;
                }
                if (this.currentRoomOrder != this.$store.state.gameModule.currentRoom.roomorder && this.isBetReset == false) {
                    this.resetGame();
                }

                this.currentRoomOrder = this.$store.state.gameModule.currentRoom.roomorder;

                if (this.$store.state.gameModule.currentRoom.state == 1) {
                    if (!this.initBetting && this.counterAdded == false) {
                        this.addBetCounter();
                        this.counterAdded = true;
                    }
                    if (this.isBetReset == false) {
                        this.resetGame();
                    }
                } else if (this.$store.state.gameModule.currentRoom.state == 2 || this.$store.state.gameModule.currentRoom.state == 2) {
                    this.initBet();
                }
                if (!this.initBetting && this.$store.state.gameModule.currentRoom.state != 1) {
                    this.counterAdded = false;
                }
            },
            deep: true
        },
        currentRoom: {
            handler() {
                if (this.currentRoom.state == 3 && !this.showResult) {
                    if (this.currentRoom.result) {
                        var result = this.currentRoom.result.split(',');
                        (result).forEach((key, item) => {
                            this.checkResultWin(result[item]);
                        });
                        this.showResult = true;
                    }
                } else {
                    if (this.currentRoom.state != 3) {
                        this.clearResultShow();
                        this.showResult = false;
                    }
                }

                if (this.currentRoom.state == 0 && this.isBetReset == false) {
                    this.resetGame();
                }
            },
            deep: true
        },
        betResponse: {
            handler(){
                if (this.betResponse == 'bettingSuccessful') {
                    //this.playAudio('betting-successful');
                    this.noBetCounter = 0;
                }
                if (this.betResponse == 'BET_CANCEL_SUCCESS') {
                    this.resetGame();
                }
            }
        },
        noBetCounter: {
            handler(val){
                if (val > this.maxAllowedNoBet) {
                    this.$store.dispatch('gameModule/forceLogout')
                }
            }
        }
    }
}
</script>

<style>
.bull-bet-area {
    bottom: 0;
    bottom: 14.5vh;
    position: absolute;
    color: white;
    text-align: center;
    z-index: 20;
}

.bet-area-label-top-bl,
.bet-area-label-bot-bl,
.bet-area-val-bl {
    display: block;
}

.bet-area-label-top-bl {
    /* font-size: 1.2vw; */
    font-weight: 900;
    font-size: 1.7rem;
}

.bet-area-mid {
    font-size: 1.4rem;
}

.bet-area-label-bot-bl {
    bottom: 0;
    right: 0;
    position: absolute;
    color: white;
    /* font-size: 15px; */
    padding-bottom: 5px;
    padding-right: 10px;
    font-weight: bolder;
}

.bet-area-val-bl {
    font-size: 1vw;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    color: gold;
}

.bet-area-col {
    border: 4px solid #cecece;
    background-color: #1c5e48;
}

.bet-area-col-left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    margin-right: 35px;
    transform: matrix(1, 0, -0.5, 0.8, 0.4, 0);
    z-index: 99;
}

.bet-area-col-right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transform: matrix(1, 0, 0.5, 0.8, 0.1, 0);
    margin-left: 35px;
}

.bet-row {
    border-bottom: 3px solid white;
    padding: 0px;
}

.bet-col {
    font-weight: 700;
    color: #2b76e6;
}

.bet-col-middle {
    border-right: 1px solid white;
}

.bet-area-title {
    text-align: center;
    font-weight: 900;
    /* font-size: 30px; */
}

.banker-text-1 {
    background: -webkit-linear-gradient(#dea0a0, #f00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #363636;
}

.player-text {
    background: -webkit-linear-gradient(#a5b2f1, #007eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #363636;
}

.bet-round {
    /* font-size: 25px; */
    font-weight: 400;
    color: #2b76e6;
}

.bet-round-text {
    color: white;
    font-weight: 700;
}

.bull-timer {
    left: 75% !important;
    right: 15% !important;
    bottom: 0;
    top: 6vh !important;
}

.betting-area-disable {
    pointer-events: none;
    opacity: 0.8;
    background: rgb(125 87 87 / 50%);
    background-color: rgb(154 154 152 / 4%);
    filter: alpha(opacity=50);
    zoom: 1;
}

.winplayer {
    -webkit-animation: blink-pwin 1s infinite;
    /* Safari 4+ */
    -moz-animation: blink-pwin 1s infinite;
    /* Fx 5+ */
    -o-animation: blink-pwin 1s infinite;
    /* Opera 12+ */
    animation: blink-pwin 1s infinite;
    /* IE 10+, Fx 29+ */
}

.winbanker {
    -webkit-animation: blink-bwin 1s infinite;
    /* Safari 4+ */
    -moz-animation: blink-bwin 1s infinite;
    /* Fx 5+ */
    -o-animation: blink-bwin 1s infinite;
    /* Opera 12+ */
    animation: blink-bwin 1s infinite;
    /* IE 10+, Fx 29+ */
}

.winbull {
    -webkit-animation: blink-bullwin 1s infinite;
    /* Safari 4+ */
    -moz-animation: blink-bullwin 1s infinite;
    /* Fx 5+ */
    -o-animation: blink-bullwin 1s infinite;
    /* Opera 12+ */
    animation: blink-bullwin 1s infinite;
    /* IE 10+, Fx 29+ */
}

@-webkit-keyframes blink-pwin {

    0%,
    49% {
        background-color: none;
    }

    50%,
    100% {
        background-color: rgba(0, 0, 255, 0.5);
    }
}

@-webkit-keyframes blink-bwin {

    0%,
    49% {
        background-color: none;
    }

    50%,
    100% {
        background-color: rgba(255, 0, 0, 0.5);
    }
}

@-webkit-keyframes blink-bullwin {

    0%,
    49% {
        background-color: none;
    }

    50%,
    100% {
        background-color: rgba(251, 255, 0, 0.5);
    }
}

.notif-div {
    font-size: 8vh;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 2vh;
}

.bet-notif-span {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 5px;
    position: absolute;
    font-size: 4vw;
    left: -3%;
    margin-top: 2vh;
    /* padding-left: 10vw; */
    /* padding-right: 10vw; */
    width: 100%;
    color: white;
}

.bet-col .col {
  cursor: pointer;
}
</style>
