<!--=========================================================================================
  File Name: VideoPlayer.vue
  Description: Video Player Component
==========================================================================================-->
<template>
<div class="content">
  <transition name="timerAnim">
    <div class="circle-timer bull-timer" v-if= "(!initBetFromBetArea && currentRoom.state == 1)">
        <div v-bind:class="[isMobile() ? 'base-timer-mobile' : 'base-timer']">
            <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g class="base-timer__circle">
                    <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                    <path id="base-timer-path-remaining" v-bind:stroke-dasharray="circleDashArr" class="base-timer__path-remaining" v-bind:class="[remainingPathColor!= '' ? remainingPathColor : '']" d="
                       M 50, 50
                       m -45, 0
                       a 45,45 0 1,0 90,0
                       a 45,45 0 1,0 -90,0
                     "></path>
                </g>
            </svg>
            <span  id="base-timer-label" v-bind:class="[baseTimeLabel!= '' ? baseTimeLabel : '',isMobile() ? 'base-timer__label_mobile' : 'base-timer__label']">
                {{ timeSecs }}
            </span>
        </div>
    </div>
  </transition>
</div>
</template>

<script>
export default {
    name: 'Timer',
    data() {
        return {
            prevState: '',
            fullDashArray: 283,
            timeLimit: 20,
            timePassed: 0,
            timeLeft: 0,
            timeInterval: null,
            COLOR_CODES: {
                info: {
                    color: "green"
                },
                warning: {
                    color: "orange",
                    threshold: 10
                },
                alert: {
                    color: "red",
                    threshold: 10
                }
            },
            circleDashArr: 283,
            remainingPathColor: "neutral",
            baseTimeLabel: "",
            initBetting: false
        }
    },
    props: {
        initBetFromBetArea: {
            required: true,
            type: Boolean
        },
    },
    computed: {
        timeSecs() {
          return this.$store.state.gameModule.currentRoom.timer
        },
        isBetClosed() {
            return this.$store.state.gameModule.currentRoom.state != 1;
        },
        currentRoom() {
            return this.$store.state.gameModule.currentRoom;
        },
    },
    watch: {
        isBetClosed: {
            handler() {
                if (this.$store.state.gameModule.currentRoom.state != 1) {

                    this.initBetting = false;
                    if (this.currentRoom.state == 3) {
                      this.prevState = ''
                    }
                } else {
                    this.initBetting = true;
                }
            },
            deep: true
        },
        timeSecs: {
            handler(value) {
                if (this.prevState == 2) {
                  this.$store.commit('gameModule/SET_ROOM_DATA', {roomno: this.currentRoom.roomno, roomorder: this.currentRoom.roomorder, state: 2, shoe: this.currentRoom.shoe, time: 0, result: null})
                  return;
                }

                if (!this.initBetFromBetArea) {

                  if (this.initBetting) {
                  // STATE 1 ONLY
                      if (this.currentRoom.initial) {
                          this.playAudio('betting-start');
                      }
                  if (value > 0) {
                      this.playAudio('countdown');
                      setTimeout(() => {
                          this.setCircleDasharray();
                          this.setRemainingPathColor(value);
                      }, 1000)
                  } else {
                      this.playAudio('betting-end');
                      // this.timeLeft = 0
                      this.setCircleDasharray();
                      this.setRemainingPathColor(value);
                  }
                  if (value <= 5 && value > 0) {
                      this.playAudio(value);
                  }

                  if (value == 0 && this.currentRoom.state == 1) {
                    this.initBetting = false;
                    this.prevState = 2

                    this.$store.commit('gameModule/SET_ROOM_DATA', {roomno: this.currentRoom.roomno, roomorder: this.currentRoom.roomorder, state: 2, shoe: this.currentRoom.shoe, time: 0, result: null})
                  }
                }
              }
            },
            deep: true
        },
    },
    methods: {
        formatTime(time) {
            var t = time;
            if (time < 10) {
                t = time;
            }
            return t;
        },
        calculateTimeFraction() {
            const rawTimeFraction = this.timeSecs / this.timeLimit;
            return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
        },
        setCircleDasharray() {
            var circleDasharray = ((this.calculateTimeFraction() * this.fullDashArray).toFixed(0)) + ' ' + 283;
            this.circleDashArr = circleDasharray;
        },
        setRemainingPathColor(timeLeft) {
            if (timeLeft <= this.COLOR_CODES.alert.threshold) {
                this.remainingPathColor = this.COLOR_CODES.alert.color;
                this.baseTimeLabel = this.COLOR_CODES.alert.color;
            } else if (timeLeft <= this.COLOR_CODES.warning.threshold) {
                this.remainingPathColor = this.COLOR_CODES.warning.color;
                this.baseTimeLabel = this.COLOR_CODES.alert.color;
            } else {
                this.remainingPathColor = this.COLOR_CODES.info.color;
            }
        },
        playAudio(id) {
            this.$emit('playAudio', id);
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style>
.timerAnim-enter-active {
  animation: timerAnim-in .5s;
}
.timerAnim-leave-active {
  animation: timerAnim-in .5s reverse;
}
@keyframes timerAnim-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity:1;
  }
}
</style>
