<!--=========================================================================================
  File Name: GameResult.vue
  Description: Game Result Component
==========================================================================================-->
<template>
<b-container class="game-results" fluid>
    <b-row>
        <b-col class="cards">
            <b-row class="cards-row" style="padding-top: 30px;">
                <b-col cols="2" class="card-data" v-for="(card, idx) in (bossCard)" :key="idx">
                    <span class="card-image" v-if="card" :class="card | lowercase"></span>
                    <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                </b-col>
                <b-col cols="10" style="left:15%;">
                    <b-row class="card-results-banker">
                        <span class="banker-text">BANKER</span>
                    </b-row>
                    <div class="row">
                        <div cols="2" class="card-data" v-for="(card, idx) in (bankerCard)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="cards-row">
                <b-col cols="4">
                    <div class="row" style="padding-left: 10px;">
                        <div class="card-data" v-for="(card, idx) in (player1Card).slice(3)" :key="idx">
                            <!-- {{card}} -->
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="row" style="padding-left: 10px;">
                        <div class="card-data" v-for="(card, idx) in (player2Card).slice(3)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="row" style="padding-left: 10px;">
                        <div class="card-data" v-for="(card, idx) in (player3Card).slice(3)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="cards-row" style="padding-bottom: 30px;">
                <b-col cols="4">
                    <div class="row">
                        <div class="card-data" v-for="(card, idx) in (player1Card).slice(0,3)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                    <div class="row">
                        <span class="top-text">PLAYER 1</span>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="row">
                        <div class="card-data" v-for="(card, idx) in (player2Card).slice(0,3)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                    <div class="row">
                        <span class="top-text">PLAYER 2</span>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="row">
                        <div class="card-data" v-for="(card, idx) in (player3Card).slice(0,3)" :key="idx">
                            <span class="card-image" v-if="card" :class="card | lowercase"></span>
                            <span class="card-image" v-if="!card" :class="'ZZ' | lowercase"></span>
                        </div>
                    </div>
                    <div class="row">
                        <span class="top-text">PLAYER 3</span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    name: 'GameResult',
    data() {
        return {
            player1Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            player2Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            player3Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            bankerCard: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            bossCard: ['ZZ']
        }
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
    },
    methods: {
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        }
    },
    filters: {
        lowercase: function (str) {
            if(str == null) {
                str = 'ZZ'
            }
            return str.toString().toLowerCase();
        },
        cardStyle: function (card) {
            var cardNumber = card.slice(0, 1);
            var cardClass = '';
            if (card != '') {
                switch (cardNumber.toString()) {
                    case 'A':
                        cardClass = '01';
                        break;
                    case '2':
                        cardClass = '02';
                        break;
                    case '3':
                        cardClass = '03';
                        break;
                    case '4':
                        cardClass = '04';
                        break;
                    case '5':
                        cardClass = '05';
                        break;
                    case '6':
                        cardClass = '06';
                        break;
                    case '7':
                        cardClass = '07';
                        break;
                    case '8':
                        cardClass = '08';
                        break;
                    case '9':
                        cardClass = '09';
                        break;
                    case 'T':
                        cardClass = '10';
                        break;
                    case 'J':
                        cardClass = '11';
                        break;
                    case 'Q':
                        cardClass = '12';
                        break;
                    case 'K':
                        cardClass = '13';
                        break;
                    case 'Z':
                        cardClass = '14';
                        break;

                }
                if (cardClass == '') {
                    cardClass = cardNumber.toString();
                }
                cardClass = card.slice(1, 2).toString().toLowerCase() + cardClass
            }
            return cardClass;
        }
    },
    watch: {
        currentRoom: {
            handler() {
                if (this.currentRoom.state == 0 && this.currentRoom.state == 5) {
                    //RESET
                    this.player1Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'];
                    this.player2Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'];
                    this.player3Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'];
                    this.bankerCard = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'];
                    this.bossCard = ['ZZ'];
                }

                if (this.currentRoom.state == 3) {
                    let initPlayer1Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
                        initPlayer2Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
                        initPlayer3Card = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
                        initBankerCard = ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
                        initBossCard = ['ZZ'];
                    if (this.containsKey(this.currentRoom.card, 'banker')) {
                        let cardData = this.currentRoom.card;
                        var bossCardData = cardData.boss;
                        var bankerCardsData = cardData.banker.highcards;
                        var player1CardsData = cardData.player1.highcards;
                        var player2CardsData = cardData.player2.highcards;
                        var player3CardsData = cardData.player3.highcards;

                        if ((player1CardsData).length > 0) {
                            //this.player2Card = this.currentRoom.card.player2.highcards;
                            if (player1CardsData.length > 0) {
                                let x = 0;
                                player1CardsData.forEach((key, item) => {
                                    initPlayer1Card[x] = player1CardsData[item][0]
                                    x++;
                                })
                            }
                        }
                        if ((player2CardsData).length > 0) {
                            //this.player2Card = this.currentRoom.card.player2.highcards;
                            if (player2CardsData.length > 0) {
                                let x = 0;
                                player2CardsData.forEach((key, item) => {
                                    initPlayer2Card[x] = player2CardsData[item][0]
                                    x++;
                                })
                            }
                        }

                        if ((player3CardsData).length > 0) {
                            //this.player3Card = this.currentRoom.card.player3.highcards;
                            if (player3CardsData.length > 0) {
                                let x = 0;
                                player3CardsData.forEach((key, item) => {
                                    initPlayer3Card[x] = player3CardsData[item][0]
                                    x++;
                                })
                            }
                        }

                        if ((bankerCardsData).length > 0) {
                            //this.bankerCard = this.currentRoom.card.banker.highcards;
                            if (bankerCardsData.length > 0) {
                                let x = 0;
                                bankerCardsData.forEach((key, item) => {
                                    initBankerCard[x] = bankerCardsData[item][0]
                                    x++;
                                })
                            }
                        }

                        if ((bossCardData).length > 0) {
                            //this.bossCard = this.currentRoom.card.boss.highcards;
                            if (bossCardData.length > 0) {
                                let x = 0;
                                bossCardData.forEach((key, item) => {
                                    initBossCard[x] = bossCardData[item];
                                    x++;
                                })
                            }
                        }

                        this.player1Card = initPlayer1Card;
                        this.player2Card = initPlayer2Card;
                        this.player3Card = initPlayer3Card;
                        this.bankerCard = initBankerCard;
                        this.bossCard = initBossCard;

                    }

                } else {
                    if (this.currentRoom.card && this.currentRoom.card.player1 && (this.currentRoom.card.player1).length > 0) {
                        this.player1Card = this.currentRoom.card.player1;
                    }

                    if (this.currentRoom.card && this.currentRoom.card.player2 && (this.currentRoom.card.player2).length > 0) {
                        this.player2Card = this.currentRoom.card.player2;
                    }

                    if (this.currentRoom.card && this.currentRoom.card.player3 && (this.currentRoom.card.player3).length > 0) {
                        this.player3Card = this.currentRoom.card.player3;
                    }

                    if (this.currentRoom.card && this.currentRoom.card.banker && (this.currentRoom.card.banker).length > 0) {
                        this.bankerCard = this.currentRoom.card.banker;
                    }

                    if (this.currentRoom.card && this.currentRoom.card.boss && (this.currentRoom.card.boss).length > 0) {
                        this.bossCard = this.currentRoom.card.boss;
                    }
                }
            },
            deep: true
        }
    }

}
</script>

<style>
.game-results {
    color: white;
    width: 25%;
    margin-left: 0 !important;
}

.cards {
    border: 3px solid #beae93;
    top: 1vh;
    margin-left: 15px;
    height: auto;
    text-align: center;
    z-index: 2;
    background: #000000ad;
}

.card-image {
    width: 2vw !important;
    height: 3vw !important;
    display: inline-block;
    background-size: cover;
}

.card-data {
    padding: 0px 5px;
}

.cards-row {
    padding: 5px;
}

.title {
    padding-left: 20px;
    font-weight: 700;
}

.cards-row-last {
    padding: 5px;
}

.border-right {
    border-right: 2px solid white;
}

.card-results-banker {
    background-image: linear-gradient(to right, #ff0000 0%, #d53a3a 90%, #ff0000 100%);
}

.card-results-player {
    background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5 90%, #00d2ff 100%);
}

.cr-last {
    border-bottom-left-radius: 20px;
}

.banker-text {
    position: absolute;
    left: -20%;
    right: 0;
    left: -50%;
    top: -45%;
    font-weight: 900;
    background: -webkit-linear-gradient(#dea0a0, #f00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.top-text {
    position: absolute;
    left: -30%;
    right: 0;
    font-weight: 900;
    background: -webkit-linear-gradient(#a5b2f1, #007eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bottom-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    color: black;
    font-size: 1.5vh;
}
</style>
