<!--=========================================================================================
  File Name: GameInformation.vue
  Description: Game Information Component
==========================================================================================-->
<template>
<b-container fluid style="color:white;position:absolute;z-index:1">
    <b-row class="info">
        <b-col class="info-col" lg="2" md="3" offset-lg="10" offset-md="9">
            <b-row>
                <b-col class="game-text">
                    <span>Table:</span>
                </b-col>
                <b-col>
                    {{room.roomno}} - {{room.roomorder}}
                </b-col>
            </b-row>
                    <b-row>
                        <b-col class="game-text">{{$t('gameroom.playerBetLimitText')}}</b-col>
                        <b-col>{{ betLimit.player_min | currency('')}} - {{ betLimit.player_max | currency('')}}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="game-text">{{$t('gameroom.bankerBetLimitText')}}</b-col>
                        <b-col>{{ betLimit.banker_min | currency('')}} - {{ betLimit.banker_max | currency('')}}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="game-text">{{$t('gameroom.bullBetLimitText')}}</b-col>
                        <b-col>{{ betLimit.pair_min | currency('')}} - {{ betLimit.pair_max | currency('')}}</b-col>
                    </b-row>
            <b-row>
                <b-col class="game-text">
                    <span>User ID:</span>
                </b-col>
                <b-col>
                    <span>{{ member.username }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="game-text">
                    <span>Balance:</span>
                </b-col>
                <b-col>
                    <span class="game-balance">{{ member.balance | currency }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="game-text">
                    <span>Total Bet</span>
                </b-col>
                <b-col>
                    <span class="game-balance">{{ totalBet | currency }}</span>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    name: 'GameInformation',
    computed: {
        member() {
            return this.$store.state.gameModule.member
        },
        room() {
            return this.$store.state.gameModule.currentRoom
        },
        bettings() {
            return this.$store.state.betModule.bettings
        },
        levels() {
            return this.$store.state.gameModule.levels
        },
        currentLevel() {
            return this.$store.state.gameModule.currentLevel
        },
        totalBet() {
            var returnAmount = 0;
            if (this.bettings) {
                this.bettings.forEach((key, item) => {
                    returnAmount += parseInt(this.bettings[item].betamt);
                });
            }
            return returnAmount;
        },
        betLimit() {
            var betLimits = this.levels;
            var returnObj = {};
            if (betLimits) {
                betLimits.forEach((key, item) => {
                    if (betLimits[item].level  == this.currentLevel) {

                        returnObj = betLimits[item];
                    }
                });
            }
            return returnObj;
        }
    }
}
</script>

<style>
.info {
    border-radius: 25px;
    margin-top: 1.2vh;
    margin-right: 15px;
    height: auto;
    font-size: .7vw !important;
    text-align: left;

}

.info-col {
    border: 3px solid #beae93;
    border-radius: 0px;
    background: #000000ad;
    padding-top: 5px;
    padding-bottom: 5px;
}

.game-text {
    color:gray;
    padding-right: 0px !important
}

.game-balance {
    background: linear-gradient(0deg, #dec481, #fadb00 80%) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
