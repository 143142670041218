<template>
<b-container fluid style="margin-left: 0px;margin-right: 0px;padding-left: 0px;padding-right: 0px;">
    <!-- Betting Timer -->
    <timer @playAudio="playAudio" :initBetFromBetArea = 'initBetting' />

    <div class="video-background">
        <div class="video-foreground">
            <iframe class= 'loading-img' :src="vidurl" frameborder="0" scrolling="no" allowfullscreen ></iframe>
            <!-- <iframe src="http://sb1.sj7080.com/pz_vm.php?m=BULL" frameborder="0" scrolling="no" allowfullscreen></iframe> -->
        </div>
    </div>
    <span :class="['btn-refresh-bull',{'loading': this.refreshLoading}]" @click="videoRefresh()"></span>
</b-container>
</template>

<script>
import Timer from '@/components/common/Timer'
export default {
    name: 'VideoPlayer',
    components: {
        Timer
    },
    props: {
        initBetting: {
            required: true,
            type: Boolean
        },
    },
    data() {
        return {
            refreshLoading: false,
            videoInterval:0
        }
    },
    mounted() {
      this.videoRefresh()
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
        vidurl() {
            if (this.refreshLoading) {
                return '';
            } else {
                return this.$store.state.gameModule.video
            }
        }
    },
    methods: {
        playAudio(id) {
            this.$emit('playAudio', id);
        },
        async videoRefresh() {

            this.$socket.client.emit('video_refresh', {  roomno: this.currentRoom.roomno, mobile: false })

            if (!this.refreshLoading) {
                this.refreshLoading = true;
                setTimeout( () => {this.refreshLoading = false;}, 1000)
            }
        },
    },
    created() {
        this.videoInterval = setInterval(this.videoRefresh, 300000);
    },
    beforeDestroy() {
      clearInterval(this.videoInterval)

    },
}
</script>

<style>
.video-background {
    background-color: #363636;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.loading-img {
    background-color: #000;
    background-image: url(/assets/images/game/history/loading.gif);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 3vw 3vw;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}

@media all and (max-width: 600px) {
    .vid-info {
        width: 50%;
        padding: 0.5rem;
    }

    .vid-info h1 {
        margin-bottom: 0.2rem;
    }
}

@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}

@media (orientation: landscape) {
    .video-background {
        /*top: 0 !important;*/
    }

}
.btn-refresh-bull {
  cursor: pointer;
  position: absolute;
  left: 10vw;
  /* top: auto; */
  display: inline-block;
  height: 2.3vh;
  width: 2.3vh;
  background: url(/assets/images/game/button/refresh.png) no-repeat;
  background-size: contain;
  margin-top: 4vh;
}
.btn-refresh-bull.loading {
    cursor: progress;
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
}
</style>
