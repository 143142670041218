<!--=========================================================================================
  File Name: ResultRoad.vue
  Description: BUll Result Road Component
==========================================================================================-->
<template>
<div class="shoe-area-bot">
    <div class="row">
        <div class="bead-road grid-y-bull" ref="shoe-tbl">
            <img v-if="showLeftArrow" @click="scrollTarget('left')" src="/assets/images/gamelobby/btn_hisprev.png" alt="Prev Button" class="shoe-prev-bull" />
            <div class="d-flex flex-row grid-x-bull" :style="`width: ${showWidth}`">
                <div class="flex-column">
                    <div class="bull-shoe-grid shoe-banker-bg shoe-player" :class="[{ 'card-start-banker': isFirstCardCurrent(0, bossCard)}]"><span class="title-shoe">B</span></div>
                    <div class="bull-shoe-grid shoe-player-bg shoe-player" :class="[{ 'card-start-player': isFirstCardCurrent(1, bossCard)}]"><span class="title-shoe">P1</span></div>
                    <div class="bull-shoe-grid shoe-player-bg shoe-player" :class="[{ 'card-start-player': isFirstCardCurrent(2, bossCard)}]"><span class="title-shoe">P2</span></div>
                    <div class="bull-shoe-grid shoe-player-bg shoe-player" :class="[{ 'card-start-player': isFirstCardCurrent(3, bossCard)}]"><span class="title-shoe">P3</span></div>
                </div>
                <div class="flex-column" v-for="(row, x) in shoeMatrix" :key="x">
                    <div v-for="(col, y) in row.slice(0,4)" :key="y" class="bull-shoe-grid" v-bind:class="[{ shoe_banker: y == 0}, { shoe_player: y != 0}, { 'card-start': isFirstCard(y, shoeMatrix[x][4])}]">
                        <span class="result-top-text" v-bind:class="[{adjust_top_text: !displayShoe(shoeMatrix[x][y])}]">{{getShoe(shoeMatrix[x][y])}}</span>
                        <span class="result-bot-text" v-if="displayShoe(shoeMatrix[x][y])">WIN</span>
                    </div>
                </div>
            </div>
            <img v-if="showRightArrow" @click="scrollTarget('right')" src="/assets/images/gamelobby/btn_hisnext.png" alt="Next Button" class="shoe-next-bull" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ResultRoad',
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            showRightArrow: false,
            showLeftArrow: false,
            width: 0,
            window: {
                width: 0,
                height: 0
            },
            scrollW: 0,
            showShoe: false,
        }
    },
    props: {
        shoeResults: {
            required: true,
            type: String
        },
        bossCard: {
            required: false,
            type: String
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    computed: {
        shoeMatrix() {
            let shoe = []
            if (this.shoeResults.length > 0) {
                shoe = this.shoeResults.split('|').map((col) => col.split(',')) || []
            }
            return shoe
        },
        showWidth() {
            return this.shoeResize();
        },
    },
    methods: {
        getShoe(shoe) {
            var shoeLetter = shoe.slice(0, 1);
            var shoeNumber = shoe.slice(1);
            var shoeValue = '';
            if (shoe != '') {
                switch (shoeLetter.toString()) {
                    case 'a':
                    case 'e':
                        shoeValue = 'No Bull';
                        break;
                    case 'b':
                    case 'f':
                        shoeValue = 'Bull ' + shoeNumber;
                        break;
                    case 'c':
                    case 'g':
                        shoeValue = 'Bull Bull';
                        break;
                    case 'd':
                    case 'h':
                        shoeValue = 'Gong';
                        break;
                    default:
                        shoeValue = ''
                        break;
                }
            }
            return shoeValue;
        },
        displayShoe: function (shoe) {
            var shoeLetter = shoe.slice(0, 1);
            let showShoe;
            if (shoe != '') {
                switch (shoeLetter.toString()) {
                    case 'a':
                    case 'b':
                    case 'c':
                    case 'd':
                        showShoe = false;
                        break;
                    case 'e':
                    case 'f':
                    case 'g':
                    case 'h':
                        showShoe = true;
                        break;
                }
            }
            return showShoe;
        },
        shoeResize() {
            return this.shoeW = ((this.window.width / 2) - 10) + 'px';
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.scrollW = Math.ceil((this.window.height * 3.23) / 100);
        },
        scrollTarget(move) {
            if (move == 'right') this.$refs['shoe-tbl'].scrollLeft += 90
            else this.$refs['shoe-tbl'].scrollLeft -= 90
        },
        isFirstCard(sequence, bossCard) {
            let showStartIcon = false;
            if (bossCard) {
                if (sequence == 0) {
                    if (bossCard == '4' || bossCard == '8' || bossCard == '12') {
                        showStartIcon = true;
                    }
                } else if (sequence == 1) {
                    if (bossCard == '1' || bossCard == '5' || bossCard == '9' || bossCard == '13') {
                        showStartIcon = true;
                    }
                } else if (sequence == 2) {
                    if (bossCard == '2' || bossCard == '6' || bossCard == '10') {
                        showStartIcon = true;
                    }
                } else if (sequence == 3) {
                    if (bossCard == '3' || bossCard == '7' || bossCard == '11') {
                        showStartIcon = true;
                    }
                }
            }

            return showStartIcon;
        },
        isFirstCardCurrent(sequence, bossCard) {
            let showStartIcon = false;
            if (bossCard) {
                if (sequence == 0) {
                    if (bossCard == '04' || bossCard == '08' || bossCard == '12') {
                        showStartIcon = true;
                    }
                } else if (sequence == 1) {
                    if (bossCard == '01' || bossCard == '05' || bossCard == '09' || bossCard == '13') {
                        showStartIcon = true;
                    }
                } else if (sequence == 2) {
                    if (bossCard == '02' || bossCard == '06' || bossCard == '10') {
                        showStartIcon = true;
                    }
                } else if (sequence == 3) {
                    if (bossCard == '03' || bossCard == '07' || bossCard == '11') {
                        showStartIcon = true;
                    }
                }
            }

            return showStartIcon;
        }
    },
    watch: {
        shoeMatrix: {
            handler(val) {
                console.log(val)
                this.handleResize();
                if (this.shoeMatrix.length > 10) {
                    this.showRightArrow = true
                    this.showLeftArrow = true
                } else {
                    this.showRightArrow = false
                    this.showLeftArrow = false
                }
            }
        }
    },
}
</script>

<style>
.bead-road {
    overflow: hidden;

}

.shoe-area-bot {
    position: absolute;
    bottom: 0;
    color: black;
    overflow: hidden;
    /* margin-left: 5vw; */
    /*    z-index: 25;*/
}

.table th,
.table td {
    padding: 0rem;
}

.shoe-table {
    margin-bottom: 3px !important;
    font-size: 0.45rem;
    color: blue;
    background: white;
    text-align: center;
}

.shoe-player {
    /* font-weight: 600; */
    /* font-size: 15px; */
    color: white;
    padding: 2px 8px !important;
}

.shoe-banker {
    /* font-weight: 600; */
    color: red;
    padding: 2px 10px !important;
}

.shoe-banker-bg {
    font-weight: 900;
    background-image: linear-gradient(to right, #ff0000 0%, #d53a3a 90%, #ff0000 100%)
}

.shoe-banker-bg:hover {
    background-position: right center;
}

.shoe-player-bg {
    font-weight: 900;
    background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5 90%, #00d2ff 100%)
}

.shoe-player-bg:hover {
    background-position: right center;
}

.shoe-win {
    /* font-size: 10px; */
    background-image: linear-gradient(to right, #254de1, #042efe, #3f44ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    color: white;
    /* font-weight: 900; */
}

.no-row-margin-lr {
    margin-left: 0px;
    margin-right: 0px;
}

.table th,
.table td {
    width: 50px;
}

.card-start {
    background: url(/assets/images/starter.png) no-repeat right;
    background-color: rgb(246, 246, 246);
    background-size: 25%;
}

.card-start-player {
    background: url(/assets/images/starter.png) no-repeat right, linear-gradient(to right, #00d2ff 0%, #3a7bd5 90%, #00d2ff 100%);
    background-size: 25%, cover;
}

.card-start-banker {
    background: url(/assets/images/starter.png) no-repeat right, linear-gradient(to right, #ff0000 0%, #d53a3a 90%, #ff0000 100%);
    background-size: 25%, cover;
}

.grid-x-bull {
    background-size: 7.5vh 100%;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, #dedede 1px, white 1px);
    padding-right: 0px !important;
}

.grid-y-bull {
    background-color: transparent;
    background-image: linear-gradient(to top, #dedede 1px, transparent 1px);
    background-repeat: repeat-y;
    background-size: 100% 4vh;
}

@media only screen and (min-width: 1402px) {
    .shoe-table {
        font-size: 0.92rem;
    }
}

.title-shoe {
    font-size: 2.3vh;
}

.bull-shoe-grid {
    height: 4vh;
    width: 7.5vh;
    text-align: center;
    /*border-top: 1px solid #D3D3D3;
    border-left: 1px solid #D3D3D3;
    background-color: white;*/
    background-color: rgb(246, 246, 246);
    border-right: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
    font-size: 1.5vh;
    font-weight: 700;
    position: relative;
}

.shoe_banker {
    color: red;
}

.shoe_player {
    color: blue;
}

.result-top-text {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    font-weight: 700;
}

.result-bot-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 1vh;
    color: white;
    background: #007eff;
}

.adjust_top_text {
    top: 10px !important;
}

.shoe-next-bull {
    z-index: 11;
    position: absolute;
    height: 8.5vh;
    top: 55px;
    right: 0;
}

.shoe-prev-bull {
    z-index: 11;
    position: absolute;
    height: 8.5vh;
    top: 55px;
}
</style>
