<!--=========================================================================================
  File Name: GameHeader.vue
  Description: Game Header Component
==========================================================================================-->
<template>
<b-container fluid>
    <b-row class="mt-1 top-head">
        <b-col>
            <main class="header mt-1 mb-1">
                <b-row>
                    <b-col class="text-left">
                        <img src="/assets/images/gameroom/back-arrow.png" fluid-grow class='top-icn-back' @click="redirectToLobby">
                    </b-col>
                    <b-col class="text-center">
                        <betting-history ref="modalName"/>
                    </b-col>
                    <b-col class="text-right">
                        <img src="/assets/images/gameroom/Profile_icon.png" fluid-grow class='img header-icn'>
                        <span class="username-span">{{ member.username }}</span>
                        <img  src="/assets/images/gameroom/btn_screen1.png" class ='img header-icn' @click="enterFullScreen" >
                        <img  src="/assets/images/gameroom/btn_history.png" class ='img header-icn'  @click="$refs.modalName.openModal()">
                        <img  src="/assets/images/gameroom/btn_sound1.png" class='img header-icn' @click="muteAudioFn()">
                        <img  src="/assets/images/gameroom/btn_setting.png" class ='img header-icn ' >
                    </b-col>
                </b-row>
            </main>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import BettingHistory from '@/views/common/pc/BettingHistory';

export default {
    name: 'GameHeader',
    components: {
        BettingHistory
    },
    mounted() {
        if (localStorage.getItem('muteAudio') === null) {
            localStorage.setItem('muteAudio', 0);
        }
        this.muteAudio = localStorage.getItem('muteAudio');
    },
    data() {
        return {
            show:false,
            langs: [
                {
                    code: 'ko-KR',
                    label: '한국어',
                    imgPath: '/assets/images/gamelobby/flags/korea.png'
                },
                {
                    code: 'en-US',
                    label: 'English',
                    imgPath: '/assets/images/gamelobby/flags/usa.png'
                },
                {
                    code: 'ch-CHINA',
                    label: '中文',
                    imgPath: '/assets/images/gamelobby/flags/china.png'
                }
            ],
            muteAudio: 0
        }
    },
    computed: {
        member() {
            return this.$store.state.gameModule.member
        }
    },
    methods: {
        redirectToLobby() {
            this.$router.push(`/lobby`)

        },
        enterFullScreen() {

            let isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||  (document.mozFullScreen || document.webkitIsFullScreen);

            if (isInFullScreen) this.cancelFullScreen();
            else this.requestFullScreen();

            return false;
        },
        cancelFullScreen() {

            let el = document.body;
            let requestMethod = el.cancelFullScreen||el.webkitCancelFullScreen||el.mozCancelFullScreen||el.exitFullscreen||el.webkitExitFullscreen;
            if (requestMethod) { // cancel full screen.
                requestMethod.call(el);
            }
        },
        requestFullScreen() {

            let el = document.body;
            let requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

            if (requestMethod) { // Native full screen.
                requestMethod.call(el);
            }
        },
        displayBetHistory() {
            this.show = !this.show;
        },
        openMoveRoom() {

        },
        muteAudioFn() {
            if (localStorage.getItem('muteAudio') === null || this.muteAudio == 0) {
                this.muteAudio = 1;
            } else {
                this.muteAudio = 0;
            }
        },

    },
    watch: {
        muteAudio(audioStatus) {
            localStorage.setItem('muteAudio', audioStatus);
        }
    }
}
</script>

<style>
.top-icn-back {
    cursor: pointer;
    padding-top: 0px !important
}

.header-icn {
    height: auto;
    max-width: 30px;
    min-width: 30px;
    cursor: pointer;
    margin: 0px 10px;
}
</style>
