<!--=========================================================================================
  File Name: GameScreen.vue
  Description: Bull Game page
==========================================================================================-->
<template>
<b-container class="bull-gamescreen" fluid style="margin-left: 0px;margin-right: 0px;padding-left: 0px;padding-right: 0px;">
    <!-- Game Header -->
    <game-header />
    <game-information />
    <game-results />
    <video-player :initBetting='initBetting' @setCurrentTimer="setCurrentTimer" />
    <betting-area :currentTimer="currentTimer" />
    <shoe-area style="margin-left: 5vw" />
</b-container>
</template>

<script>
import GameHeader from '@/components/common/GameHeaderN'
import GameInformation from '@/components/bull/game/GameInformation'
import GameResults from '@/components/bull/game/GameResults'
import VideoPlayer from '@/components/bull/game/VideoPlayer'
import BettingArea from '@/components/bull/game/BettingArea'
import ShoeArea from '@/components/bull/game/ShoeArea'

export default {
    name: "GameScreen",
    components: {
        GameHeader,
        GameInformation,
        GameResults,
        VideoPlayer,
        BettingArea,
        ShoeArea
    },
    data() {
        return {
            timer: 60,
            initBetting: false,
            currentTimer: 0,
        }
    },
    created() {
        this.$store.dispatch('gameModule/checkIfLoggedIn')
    },
    methods: {
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        setCurrentTimer(val) {
            this.currentTimer = val;
		},
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
    }
}
</script>

<style>
@import '../../../assets/scss/bull-main.scss';
@import '../../../assets/scss/mobile/spinning_bet_bg_pc.scss';
</style>
